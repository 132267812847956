import React, {forwardRef, PropsWithChildren} from 'react';
import clsx from 'clsx';

import mapPropsToStyleNames from 'shared/ui/helpers/mapPropsToStyleNames';
import symbols from 'shared/ui/symbols';

import BaseButton, {FlavoredButtonProps} from '../base';

import styles from './styles.scss';

type ButtonTonalProps = FlavoredButtonProps;

const ButtonTonal = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonTonalProps>>(
  ({neutral, caution, danger, muted, informational, ai, ...props}, ref) => {
    const flavor = mapPropsToStyleNames([{neutral, caution, danger, muted, informational, ai, default: 'default'}]);

    return <BaseButton {...props} ref={ref} className={clsx(styles[flavor], props.className)} />;
  }
);

ButtonTonal.displayName = 'Button.Tonal';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
ButtonTonal[symbols.Button.Tonal] = true;

export default ButtonTonal;
